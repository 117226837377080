import './App.css';
import { convertTimestampReadable, secondsToReadable, parseXML } from './Helper';
import React, { useState, useEffect } from 'react';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LogoutIcon from '@mui/icons-material/Logout';
import CircularProgress from '@mui/material/CircularProgress';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSnackbar } from 'notistack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Divider from '@mui/material/Divider';

//soap builder function
//kommentar modal zum stempeln?

function App() {
  let [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.sessionid)
  let [isLoading, setIsLoading] = useState(false)
  let [loginError, setLoginError] = useState(false)
  let [timeToday, setTimeToday] = useState(0)
  let [balanceWithoutCorrectionOnThisDay, setBalanceWithoutCorrectionOnThisDay] = useState("00:00")
  let [beginTime, setBeginTime] = useState("00:00")
  let [pause, setPause] = useState(0)
  let [currentMission, setCurrentMission] = useState(false)
  let [currentMissionState, setCurrentMissionState] = useState(false)
  let [username, setUsername] = useState()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (isLoggedIn) {
      loadDashboardValues()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn])

  useEffect(() => {
    if (currentMissionState === true) {
      setTimeToday(timeToday++)
      const timer = setInterval(() => {
        setTimeToday(timeToday++)
      }, 1000)
      return () => clearInterval(timer)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMissionState])

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
          primary: {
            main: '#2196f3',
          }
        },
      }),
    [prefersDarkMode],
  )

  if (isLoggedIn && localStorage.logintime && localStorage.logintime < new Date().getTime()-3600000) {
    setIsLoggedIn(false)
  }

  return isLoggedIn ? dashboardPage() : loginPage()
  
  function loginPage() {
    let isUsernameSet = localStorage.personalnr ? true : false
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box className="loadingbar-box">
          {isLoading && <LinearProgress />}
        </Box>
        <div className="login-content">
          <h1>StarTime</h1>
          <form onSubmit={login}>
            <div>
              <TextField autoFocus={!isUsernameSet} type="text" name="username" label="Personalnummer" defaultValue={localStorage.personalnr} variant="outlined" inputProps={{ pattern: "[0-9]{1,8}" }} required/>
            </div><br></br>
            <div>
              <TextField autoFocus={isUsernameSet} type="password" id="password" name="password" label="Passwort" variant="outlined" className={loginError?"login-pw-error":""} error={!!loginError} helperText={loginError} onChange={()=>{setLoginError(false)}} required/>
            </div>
            <div className="loginbutton">
              <Button id="loginbutton" type="submit" variant="contained" size="large" disabled={isLoading}>Login {isLoading && <CircularProgress size={20}  sx={{marginLeft: '12px'}} color="inherit"/>}</Button>
            </div>
          </form>
        </div>
      </ThemeProvider>
    )
  }

  function dashboardPage() {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box className="loadingbar-box">
          {isLoading && <LinearProgress className="loadingbar" />}
        </Box>
        <div id="dashboard">
          <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
              <Toolbar>
                <AccessTimeIcon />
                <Typography className="appbar-text" variant="h5" sx={{ flexGrow: 1 }}>StarTime</Typography>
                <IconButton color="inherit" onClick={logout}><LogoutIcon /></IconButton>
              </Toolbar>
            </AppBar>
          </Box>
          <div className="dashboard-content">
            <Typography variant="h6" className="headline">Dashboard - {username}</Typography>
            <Grid container spacing={2}>
              <Grid item xs={6} md={3}>
                <Card className="dashboard-card">
                  <CardContent>
                    <Typography variant="h5">
                      {secondsToReadable(timeToday)} h
                    </Typography>
                    <Typography color="text.secondary">
                      Heute
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6} md={3}>
                <Card className="dashboard-card">
                  <CardContent>
                    <Typography variant="h5">
                      {secondsToReadable(pause)} h
                    </Typography>
                    <Typography color="text.secondary">
                      Pause
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6} md={3}>
                <Card className="dashboard-card">
                  <CardContent>
                    <Typography variant="h5">
                      {beginTime} Uhr
                    </Typography>
                    <Typography color="text.secondary">
                      Beginn
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6} md={3}>
                <Card className="dashboard-card">
                  <CardContent>
                    <Typography variant="h5">
                      {balanceWithoutCorrectionOnThisDay} h
                    </Typography>
                    <Typography color="text.secondary">
                    Überstunden
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Divider variant="middle" />
            <Typography variant="h6" className="headline">Stempeln</Typography>
            <Grid container spacing={2} className="stempeln">
              <Grid item xs={6} md={3}>
                <Button id="kommenHirschvogel" variant="contained" size="large" disabled={isLoading} onClick={()=>{startMissionTimeBooking(150)}}>Hirschvogel</Button>
              </Grid>
              <Grid item xs={6} md={3}>
                <Button id="kommenHomeoffice" variant="contained" size="large" disabled={isLoading} onClick={()=>{startMissionTimeBooking(156, "Homeoffice")}}>Homeoffice</Button>
              </Grid>
              <Grid item xs={6} md={3}>
                <Button id="kommenSCR" variant="contained" size="large" disabled={isLoading} onClick={()=>{startMissionTimeBooking(112, "Stern Center")}}>Stern Center</Button>
              </Grid>
              <Grid item xs={6} md={3}>
                <Button id="kommenSCRFahrzeit" variant="contained" size="large" disabled={isLoading} onClick={()=>{startMissionTimeBooking(151)}}>SCR Fahrzeit</Button>
              </Grid>
              <Grid item xs={12} md={3}>
               <Button id="gehen" variant="contained" color="error" size="large" disabled={isLoading} onClick={finishTimeBooking}>Gehen</Button>
              </Grid>
            </Grid>
            { currentMission &&
              <div>
                <Divider variant="middle" />
                {currentMissionState ? <h3>Aktuell auf Mission {currentMission}</h3> : <h3>Letzte Mission: {currentMission}</h3>}
              </div>
            }
          </div>
        </div>
      </ThemeProvider>
    )
  }

  function fetchHelper(body) {
    //let startimeURL = "https://intranet.hivo.lokal/startime/api/soapService" //consistent 4s login delay from intranet server
    //let startimeURL = "https://startime2.hivo.lokal/api/soapService" //often 1 min first request delay
    let startimeURL = "https://startime3.hivo.lokal/api/soapService"
    const timeout = 10000
    const controller = new AbortController()
    const timeoutId = setTimeout(() => controller.abort(), timeout)
    return new Promise((resolve, reject) => {
      fetch(startimeURL, {method: "POST", body: body, signal: controller.signal})
        .then(response => {
          if (response.ok) {
            response.text().then(data => {
              clearTimeout(timeoutId)
              resolve(data)
            }).catch(err => {
              console.log(".text error: " + err)
              enqueueSnackbar(".text error, see console", { variant: 'error' })
              clearTimeout(timeoutId)
              reject(err)
            })
          } else {
            console.log("fetch error: " + response.status)
            enqueueSnackbar("fetch error, see console", { variant: 'error' })
            clearTimeout(timeoutId)
            reject()
          }
        })
        .catch(err => {
          console.log("catch error: " + err)
          enqueueSnackbar("Network error/timeout", { variant: 'error' })
          clearTimeout(timeoutId)
          reject(err)
        })
    })
  }

  function login(e) {
    e.preventDefault()
    setLoginError(false)
    setIsLoading(true)
    let soap = '<?xml version="1.0" encoding="UTF-8"?><SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ns142="http://api.zesi.de/zeitwirtschaft/businesslogic/user/login" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:SOAP-ENC="http://schemas.xmlsoap.org/soap/encoding/" SOAP-ENV:encodingStyle="http://schemas.xmlsoap.org/soap/encoding/"><SOAP-ENV:Header><etag method="checkLogin" uri="http://api.zesi.de/zeitwirtschaft/businesslogic/user/login"></etag></SOAP-ENV:Header><SOAP-ENV:Body><ns142:checkLogin xmlns:ns142="http://api.zesi.de/zeitwirtschaft/businesslogic/user/login" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"><param0 xsi:type="xsd:String">M01</param0><param1 xsi:type="xsd:String">'+e.target.username.value+'</param1><param2 xsi:type="xsd:String">'+e.target.password.value+'</param2><param3 xsi:type="xsd:boolean">true</param3><param4 xsi:type="xsd:boolean">false</param4><param5 xsi:type="xsd:String">null</param5><param6 xsi:type="xsd:String">de</param6><param7 xsi:type="xsd:String">null</param7></ns142:checkLogin></SOAP-ENV:Body></SOAP-ENV:Envelope>'
    fetchHelper(soap).then(data => {
      //console.log("raw: "+data)
      let xmlDoc = parseXML(data)
      let checkLoginResponse = xmlDoc.querySelectorAll("checkLoginResponse")
      if (checkLoginResponse[0]["childNodes"][0]["innerHTML"]) {
        if (checkLoginResponse[0]["childNodes"][0]["innerHTML"] === "-4") {
          setLoginError("Login fehlgeschlagen")
          document.getElementById("password").focus()
          setIsLoading(false)
        } else {
          console.log("got sessionid: "+checkLoginResponse[0]["childNodes"][0]["innerHTML"])
          localStorage.sessionid = checkLoginResponse[0]["childNodes"][0]["innerHTML"]
          localStorage.personalnr = e.target.username.value
          localStorage.logintime = new Date().getTime()
          setLoginError()
          setIsLoggedIn(true)
        }
      } else {
        setLoginError("Login fehlgeschlagen")
        setIsLoading(false)
      }
    }).catch(()=>{setIsLoading(false)})
  }

  function logoutForce() {
    localStorage.removeItem("sessionid")
    setCurrentMissionState(false) //so that the timeToday is correct counted after relogon without refresh
    setIsLoading(false)
    setIsLoggedIn(false)
  }

  function logout() {
    //actually logout on server?
    logoutForce()
  }
  
  function loadDashboardValues() {
    setIsLoading(true)
    console.log("loadDashboardValues was called, " + isLoggedIn)
    getPersonInfo()
  }

  function getPersonInfo() {
    let soap = '<?xml version="1.0" encoding="UTF-8"?><SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ns142="http://api.zesi.de/zeitwirtschaft/businesslogic/user/login" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:SOAP-ENC="http://schemas.xmlsoap.org/soap/encoding/" SOAP-ENV:encodingStyle="http://schemas.xmlsoap.org/soap/encoding/"><SOAP-ENV:Header><sessionId>'+localStorage.sessionid+'</sessionId><etag method="getPersonForSession" uri="http://api.zesi.de/zeitwirtschaft/businesslogic/user/login"></etag></SOAP-ENV:Header><SOAP-ENV:Body><ns142:getPersonForSession/></SOAP-ENV:Body></SOAP-ENV:Envelope>'
    fetchHelper(soap).then(data => {
      //console.log("raw: "+data)
      if (data.includes("The Session is not valid") || data.includes("Session-ID is unknown")) {
        console.log("The Session is not valid, logout")
        enqueueSnackbar("Sitzung abgelaufen")
        logoutForce()
        return
      }
      let xmlDoc = parseXML(data)
      let personid = xmlDoc.querySelectorAll("personId")
      if (personid) {
        console.log("got personid: "+personid[0]["innerHTML"])
        localStorage.personid = personid[0]["innerHTML"]
        getIsTimeOnDay()
      } else {
        console.log("session id unknown")
        logoutForce()
      }
      let mechanicid = xmlDoc.querySelectorAll("mechanicId")
      if (mechanicid) {
        console.log("got mechanicid: "+mechanicid[0]["innerHTML"])
        localStorage.mechanicid = mechanicid[0]["innerHTML"]
        getCalculatedDayOfCalculationDay()
        getCurrentMissionTimeBooking()
      } else {
        console.log("mechanicid id unknown")
        logoutForce()
      }
      let firstname = xmlDoc.querySelectorAll("firstname")
      let lastname = xmlDoc.querySelectorAll("lastname")
      if (firstname && lastname) {
        console.log("got name: "+firstname[0]["innerHTML"]+" "+lastname[0]["innerHTML"])
        setUsername(firstname[0]["innerHTML"]+" "+lastname[0]["innerHTML"])
      } else {
        console.log("name unknown")
        logoutForce()
      }
    }).catch(setIsLoading(false))
  }
  
  function getIsTimeOnDay() {
    let soap = '<?xml version="1.0" encoding="UTF-8"?><SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ns177="http://api.zesi.de/zeitwirtschaft/dao/user/person#'+localStorage.personid+'" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:SOAP-ENC="http://schemas.xmlsoap.org/soap/encoding/" SOAP-ENV:encodingStyle="http://schemas.xmlsoap.org/soap/encoding/"><SOAP-ENV:Header><sessionId>'+localStorage.sessionid+'</sessionId><etag method="getIsTimeOnDay" uri="http://api.zesi.de/zeitwirtschaft/dao/user/person#'+localStorage.personid+'"></etag></SOAP-ENV:Header><SOAP-ENV:Body><ns177:getIsTimeOnDay xmlns:ns177="http://api.zesi.de/zeitwirtschaft/dao/user/person#'+localStorage.personid+'" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"><param0 xsi:type="xsd:long">0</param0></ns177:getIsTimeOnDay></SOAP-ENV:Body></SOAP-ENV:Envelope>'
    fetchHelper(soap).then(data => {
      //console.log("raw: "+data)
      let xmlDoc = parseXML(data)
      let getIsTimeOnDayResponse = xmlDoc.querySelectorAll("getIsTimeOnDayResponse")
      if (!getIsTimeOnDayResponse) {
        console.log("got faultstring, redirecting to login")
        console.log(data)
        logoutForce()
        return
      } else if (getIsTimeOnDayResponse[0]["childNodes"][0]["innerHTML"] === "-1") {
        //heute noch nicht gestempelt
        setTimeToday(0)
      } else {
        setTimeToday(parseInt(getIsTimeOnDayResponse[0]["childNodes"][0]["innerHTML"]))
        console.log("got timeToday: " + getIsTimeOnDayResponse[0]["childNodes"][0]["innerHTML"])
      }
    })
  }

  function getCalculatedDayOfCalculationDay() {
    let soap = '<?xml version="1.0" encoding="UTF-8"?><SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ns'+localStorage.mechanicid+'="http://api.zesi.de/zeitwirtschaft/businesslogic/calculation/calculateddays" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:SOAP-ENC="http://schemas.xmlsoap.org/soap/encoding/" SOAP-ENV:encodingStyle="http://schemas.xmlsoap.org/soap/encoding/"><SOAP-ENV:Header><sessionId>'+localStorage.sessionid+'</sessionId><etag method="getCalculatedDayOfCalculationDay" uri="http://api.zesi.de/zeitwirtschaft/businesslogic/calculation/calculateddays"></etag></SOAP-ENV:Header><SOAP-ENV:Body><ns'+localStorage.mechanicid+':getCalculatedDayOfCalculationDay xmlns:ns'+localStorage.mechanicid+'="http://api.zesi.de/zeitwirtschaft/businesslogic/calculation/calculateddays" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"><param0 xsi:type="xsd:int">'+localStorage.personid+'</param0><param1 xsi:type="xsd:long">'+Date.now()+'</param1></ns'+localStorage.mechanicid+':getCalculatedDayOfCalculationDay></SOAP-ENV:Body></SOAP-ENV:Envelope>'
    fetchHelper(soap).then(data => {
      //console.log("raw: "+data)
      let xmlDoc = parseXML(data)
      console.log(xmlDoc)
      let balanceWithoutCorrectionOnThisDay = xmlDoc.querySelectorAll("balanceWithoutCorrectionOnThisDay")
      if (balanceWithoutCorrectionOnThisDay) {
        console.log("got balanceWithoutCorrectionOnThisDay: "+balanceWithoutCorrectionOnThisDay[0]["innerHTML"])
        setBalanceWithoutCorrectionOnThisDay(secondsToReadable(balanceWithoutCorrectionOnThisDay[0]["innerHTML"]).slice(0,-3))
      } else {
        console.log("session id unknown")
        logoutForce()
      }
      let calculatedMissionTimeBookings = xmlDoc.querySelectorAll("calculatedMissionTimeBookings")
      if (calculatedMissionTimeBookings) {
        console.log("got calculatedMissionTimeBookings: ")
        console.log(calculatedMissionTimeBookings[0]["childNodes"])
        if (calculatedMissionTimeBookings[0]["childNodes"].length > 0) {
          /*calculatedMissionTimeBookings[0]["childNodes"].forEach(a => {
            console.log("foreach:")
            console.log(a.getElementsByTagName('timeTypeName')[0]["innerHTML"])
          })*/
          setCurrentMission(calculatedMissionTimeBookings[0]["childNodes"][calculatedMissionTimeBookings[0]["childNodes"].length-1].getElementsByTagName('timeTypeName')[0]["innerHTML"])
          let lastMissionState = calculatedMissionTimeBookings[0]["childNodes"][calculatedMissionTimeBookings[0]["childNodes"].length-1].getElementsByTagName('inProgress')[0]["innerHTML"]
          lastMissionState === "true" ? setCurrentMissionState(true) : setCurrentMissionState(false)
          setBeginTime(convertTimestampReadable(calculatedMissionTimeBookings[0]["childNodes"][0].getElementsByTagName('startTime')[0]["innerHTML"]))
        }
        let breakTarget = xmlDoc.querySelectorAll("breakTarget")
        if (breakTarget) {
          setPause(breakTarget[0]["innerHTML"])
        }
        setIsLoading(false)
      } else {
        console.log("session id unknown")
        logoutForce()
      }
    })
  }

  function getCurrentMissionTimeBooking() {
    let soap = '<?xml version="1.0" encoding="UTF-8"?><SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ns203="http://api.zesi.de/zeitwirtschaft/dao/user/mechanic#'+localStorage.mechanicid+'" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:SOAP-ENC="http://schemas.xmlsoap.org/soap/encoding/" SOAP-ENV:encodingStyle="http://schemas.xmlsoap.org/soap/encoding/"><SOAP-ENV:Header><sessionId>'+localStorage.sessionid+'</sessionId><etag method="getCurrentMissionTimeBooking" uri="http://api.zesi.de/zeitwirtschaft/dao/user/mechanic#'+localStorage.mechanicid+'"></etag></SOAP-ENV:Header><SOAP-ENV:Body><ns203:getCurrentMissionTimeBooking/></SOAP-ENV:Body></SOAP-ENV:Envelope>'
    fetchHelper(soap).then(data => {
      console.log("raw (todo): "+data)
      let xmlDoc = parseXML(data)
      let getCurrentMissionTimeBookingResponse = xmlDoc.querySelectorAll("getCurrentMissionTimeBookingResponse")
      if (!getCurrentMissionTimeBookingResponse) {
        console.log("got faultstring, redirecting to login")
        console.log(data)
        logoutForce()
        return
      }
    })
  }

  function finishTimeBooking() {
    setIsLoading(true)
    var soap = '<?xml version="1.0" encoding="UTF-8"?><SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ns182="http://api.zesi.de/zeitwirtschaft/services/timebooking/bookingservice" xmlns:ns177="http://api.zesi.de/zeitwirtschaft/dao/user/person#'+localStorage.personid+'" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:SOAP-ENC="http://schemas.xmlsoap.org/soap/encoding/" SOAP-ENV:encodingStyle="http://schemas.xmlsoap.org/soap/encoding/"><SOAP-ENV:Header><sessionId>'+localStorage.sessionid+'</sessionId><etag method="finishTimeBooking" uri="http://api.zesi.de/zeitwirtschaft/services/timebooking/bookingservice"></etag><etag method="load" uri="http://api.zesi.de/zeitwirtschaft/dao/user/person#'+localStorage.personid+'"></etag></SOAP-ENV:Header><SOAP-ENV:Body><ns182:finishTimeBooking xmlns:ns182="http://api.zesi.de/zeitwirtschaft/services/timebooking/bookingservice" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"><param0 xsi:type="xsd:int">'+localStorage.personid+'</param0></ns182:finishTimeBooking><ns177:load/></SOAP-ENV:Body></SOAP-ENV:Envelope>'
    fetchHelper(soap).then(data => {
      console.log("raw finishTimeBooking: "+data)
      if (data.includes("The Session is not valid") || data.includes("Session-ID is unknown")) {
        console.log("The Session is not valid, logout")
        enqueueSnackbar("Sitzung abgelaufen", { variant: 'error' })
        logoutForce()
        return
      }
      let xmlDoc = parseXML(data)
      console.log(xmlDoc)
      enqueueSnackbar("Ausgestempelt", { variant: 'success' })
      loadDashboardValues()
    })
  }
  
  function startMissionTimeBooking(missionId, comment) {
    setIsLoading(true)
    console.log("startMissionTimeBooking: "+missionId)
    let soap = '<?xml version="1.0" encoding="UTF-8"?><SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ns189="http://api.zesi.de/zeitwirtschaft/dao/user/mechanic#'+localStorage.mechanicid+'" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:SOAP-ENC="http://schemas.xmlsoap.org/soap/encoding/" SOAP-ENV:encodingStyle="http://schemas.xmlsoap.org/soap/encoding/"><SOAP-ENV:Header><sessionId>'+localStorage.sessionid+'</sessionId><etag method="startMissionTimeBooking" uri="http://api.zesi.de/zeitwirtschaft/dao/user/mechanic#'+localStorage.mechanicid+'"></etag></SOAP-ENV:Header><SOAP-ENV:Body><ns189:startMissionTimeBooking xmlns:ns189="http://api.zesi.de/zeitwirtschaft/dao/user/mechanic#'+localStorage.mechanicid+'" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"><param0 xsi:type="xsd:int">0</param0><param1 SOAP-ENC:arrayType="[0]" xsi:type="SOAP-ENC:Array"></param1><param2 xsi:type="xsd:int">'+missionId+'</param2><param3 xsi:type="xsd:String">'+comment+'</param3></ns189:startMissionTimeBooking></SOAP-ENV:Body></SOAP-ENV:Envelope>'
    fetchHelper(soap).then(data => {
      console.log("raw startMissionTimeBooking: "+data)
      if (data.includes("The Session is not valid") || data.includes("Session-ID is unknown")) {
        console.log("The Session is not valid, logout")
        enqueueSnackbar("Sitzung abgelaufen", { variant: 'error' })
        logoutForce()
        return
      }
      let xmlDoc = parseXML(data)
      console.log(xmlDoc)
      enqueueSnackbar("Eingestempelt", { variant: 'success' })
      loadDashboardValues()
    })
  }
}

export default App
