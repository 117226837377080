/*import enqueueSnackbar from './App'

function fetchHelper(body) {
    //let startimeURL = "https://intranet.hivo.lokal/startime/api/soapService" //consistent 4s login delay from intranet server
    //let startimeURL = "https://startime2.hivo.lokal/api/soapService" //often 1 min first request delay
    let startimeURL = "https://startime3.hivo.lokal/api/soapService"
    const timeout = 10000
    const controller = new AbortController()
    const timeoutId = setTimeout(() => controller.abort(), timeout)
    return new Promise((resolve, reject) => {
      fetch(startimeURL, {method: "POST", body: body, signal: controller.signal})
        .then(response => {
          if (response.ok) {
            response.text().then(data => {
              clearTimeout(timeoutId)
              resolve(data)
            }).catch(err => {
              console.log(".text error: " + err)
              enqueueSnackbar(".text error, see console", { variant: 'error' })
              clearTimeout(timeoutId)
              reject(err)
            })
          } else {
            console.log("fetch error: " + response.status)
            enqueueSnackbar("fetch error, see console", { variant: 'error' })
            clearTimeout(timeoutId)
            reject()
          }
        })
        .catch(err => {
          console.log("catch error: " + err)
          enqueueSnackbar("Network error/timeout", { variant: 'error' })
          clearTimeout(timeoutId)
          reject(err)
        })
    })
}
export { fetchHelper }
line 32:
Uncaught (in promise) Error: Invalid hook call. Hooks can only be called inside of the body of a function component. This could happen for one of the following reasons:
1. You might have mismatching versions of React and the renderer (such as React DOM)
2. You might be breaking the Rules of Hooks
3. You might have more than one copy of React in the same app
See https://reactjs.org/link/invalid-hook-call for tips about how to debug and fix this problem.
*/

function convertTimestampReadable(timestamp) {
    let date = new Date(parseInt(timestamp))
    return date.toString().substr(16,5)
}
export { convertTimestampReadable }

function secondsToReadable(seconds) {
    let date = new Date(null)
    date.setSeconds(seconds)
    let hhmmssFormat = date.toISOString().substr(11, 8)
    return hhmmssFormat
}
export { secondsToReadable }

function parseXML(data) {
    let parser = new DOMParser()
    return parser.parseFromString(data, "text/xml")
}
export { parseXML }
